.all-content-container {
    display: flex;
    width: 96%;
    margin-top: 1em;
    flex-direction: column;
    position: relative;
    align-self: center;
    max-width: 100em;
}

.all-content-content-listed {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-top: 3em;
}

.learning-path-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: center;
}

.learning-path-overall-header-container {
    display: flex;
    padding: 0;
    margin: 0;
    flex-direction: row;
}

.learning-path-overall-header {
    display: flex;
    padding: 0;
    margin: 0;
}

.learning-path-overall-header-container img {
    height: 2.5em;
    margin-left: .5em;
    width: auto;
}

.learning-path-header-list {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}

.learning-path-list-container {
    display: flex;
    flex-direction: column;
}

.learning-grid-add-to-roadmap {
    display: flex;
    width: fit-content;
    align-self: end;
    gap: 1em;
    margin-top: .2em;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    padding: .2em 1em;
    border-radius: 8px;
    border: 1px solid var(--border-default);
    height: 2em;
}

.learning-grid-add-to-roadmap:hover {
    background: var(--border-default);
}

.learning-path-list-content {
    display: flex;
    flex-direction: row;
    gap: 1em;
    overflow: visible;
    align-items: stretch;
    position: relative;
}

.learning-grid-item-container {
    display: flex;
    flex-direction: column;
}

.learning-path-container-wrapper {
    display: flex;
    overflow-x: auto;
    align-items: stretch;
    overflow-y: visible;
    width: 100%;
    padding: 0;
    margin-bottom: 3em;
}

.learning-path-container {
    display: grid;
    column-gap: 3em;
    row-gap: 2em;
    margin-bottom: 1em;
    margin-left: .2em;
    overflow: visible;
    max-width: 100em;
    width: 100%;
    align-self: center;
}

.learning-path-start-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 2em;
}

.learning-path-progress-container {
    display: flex;
    width: 8em;
    height: 8em;
}

.learning-path-header-container {
    display: flex;
    width: auto;
    flex-direction: column;
    gap: .3em;
    align-self: center;
}

.learning-path-start-container h4 {
    color: var(--text-color);
}

.learning-path-start-container h5 {
    color: var(--secondary-text-color);
    font-weight: normal;
}

.learning-path-header {
    margin: .2em 0 .5em 0;
    color: var(--secondary-text-color);
    font-weight: normal;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 0 1em;
    border-bottom: 1px solid var(--secondary-text-color);
    max-lines: 1;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    white-space: nowrap;
}

.learning-path-header.first {
    padding-left: 0;
}

.selected-header {
    color: var(--primary1);
    border-bottom: 1px solid var(--primary1);
}

.learning-grid-item-header {
    color: var(--primary-blue);
    font-size: 1.1em;
    font-weight: bolder;
}

.learning-grid-item-description {
    color: var(--text-color);
    padding: 0;
    margin: 0;
    font-size: .9em;
}

.learning-grid-item-icons-list-container {
    display: flex;
    flex-direction: row;
    gap: 1em;
    margin-top: .5em;
    align-items: center;
}

.learning-grid-item-icon-container {
    display: flex;
    flex-direction: row;
    gap: .5em;
    font-size: .8em;
    padding: 0;
    align-items: center;
    color: var(--secondary-text-color);
}

.learning-grid-item-icon-container h5 {
    font-size: 1em;
    padding: 0;
    margin: 0;
}

.timeline-item-info-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-left: .1em;
    padding: .4em .5em .5em .5em;
}

.timeline-image {
    width: 100%;
    border-radius: .5em .5em 0 0;
}

#learning-path-item-header {
    font-size: 1.1rem;
    color: var(--primary-blue);
}

#learning-path-item-description {
    font-size: .8rem;
    margin: 0;
    color: var(--secondary-text-color);
}

.timeline-grid-item-icons-list-container {
    display: flex;
    flex-direction: row;
    gap: 1em;
    height: 100%;
    align-items: end;
}

.add-to-roadmap-icon-container {
    position: absolute;
    right: 1em;
    top: 1em;
    display: flex;
    width: 2em;
    height: 2em;
    background: var(--background-darken);
    border-radius: 999px;
    padding: 0;
    margin: 0;
}

#add-to-roadmap-icon {
    font-size: 2rem;
    color: white;
}

#add-to-roadmap-icon:hover {
    color: var(--border-default);
}

#learning-path-item-type {
    display: flex;
    padding: 0;
    margin: 0;
    font-size: .7em;
    color: var(--primary1);
    font-weight: normal;
    margin-bottom: -.2em;
}

.grid-item-container {
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    border-radius: 1em;
    gap: .2em;
    padding: .6em;
    background: var(--background-container-default);
    border: 1px solid var(--border-default);
    overflow: visible;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.grid-item-container:hover {
    background-color: var(--blue-background);
}

.grid-item-container.courses {
    width: 20em;
}

.grid-item-container.courses img {
    min-height: 10em;
}

.grid-item-container.interviews {
    width: 18em;
}

.grid-item-container.interviews img {
    min-height: 8em;
}

.grid-item-container.resources {
    width: 12em;
}

.grid-item-container.resources img {
    min-height: 5em;
}

.grid-item-container.tools {
    width: 16em;
    margin-top: 1em;
}

.grid-item-container.articles {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    height: 8em;
    gap: 1em;
}

.grid-item-container.articles .learning-grid-item-icons-list-container {
    margin-top: 2em;
}

.grid-item-container.articles img {
    object-fit: contain;
    height: 100%;
    width: auto;
}

.grid-item-info-container {
    display: flex;
    flex-direction: column;
}

.grid-item-container img {
    display: flex;
    border-radius: 1em;
    width: 100%;
    height: auto;
}

.roadmap-section-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1em;
    justify-content: center;
    align-items: center;
    margin-top: 0em;
}

.roadmap-element {
    display: flex;
    position: relative;
    flex-direction: row;
    height: 8em;
    width: 100%;
    gap: 1em;
}

.roadmap-element.actions {
    height: auto;
}

#action-items-disclosure {
    color: var(--secondary-text-color);
    font-size: .9rem;
    font-style: italic;
}

.roadmap-element.actions .roadmap-item-info-container {
    justify-content: start;
}

.roadmap-item-content-container {
    display: flex;
    flex-direction: row;
    background: var(--background-container-default);
    border: 1px solid var(--border-default);
    border-radius: 8px;
    width: 100%;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    overflow: clip;
    cursor: pointer;
    gap: 1em;
    height: 100%;
    position: relative;
}

.roadmap-item-image-container {
    display: flex;
    position: relative;
    height: 100%;
    width: auto;
}

.roadmap-item-content-container.completed {
    background: #c8c8c8;
}

.roadmap-item-content-container.actions .roadmap-item-icon-container {
    position: inherit;
    margin-left: 1em;
    background: none;
}

.roadmap-item-icon-container.onboarding .roadmap-item-icon {
    font-size: 1rem;
}

.roadmap-item-icon-container.onboarding .roadmap-icon-name {
    font-size: 1rem;
}

.roadmap-item-checkbox {
    display: flex;
    position: absolute;
    right: .5em;
    bottom: .5em;
    justify-content: center;
    align-items: center;
    gap: .4em;
}

.roadmap-section-remove-container {
    display: flex;
    position: absolute;
    top: .5em;
    right: .5em;
}

.roadmap-section-remove {
    display: flex;
    font-size: 1.2em;
}

.roadmap-item-checkbox input {
    transform: scale(1.2);
    align-self: center;
    accent-color: var(--secondary-text-color);
}

.roadmap-item-icon-container {
    display: flex;
    flex-direction: row;
    gap: .5em;
    position: absolute;
    left: 0;
    bottom: -.1em;
    background: white;
    width: 11em;
    border-radius: 0 8px 0 0;
    padding: 0;
    justify-content: center;
    align-items: center;
}

.roadmap-item-icon {
    display: flex;
    flex-direction: column;
    border-radius: 9999em;
    align-self: center;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    padding: 0;
    margin: 0;
}

#roadmap-section-arrow {
    display: flex;
    margin-left: 5em;
    color: var(--secondary-text-color);

}

.roadmap-icon-name {
    display: flex;
    font-size: 1.2rem;
}

.roadmap-icon-name.courses {
    color: #ed8042;
}

.roadmap-item-icon.courses {
    color: #ed8042;
}

.roadmap-icon-name.interviews {
    color: #4ea4e7;
}

.roadmap-item-icon.interviews {
    color: #4ea4e7;
}

.roadmap-icon-name.resources {
    color: #5faa62;
}

.roadmap-item-icon.resources {
    color: #5faa62;
}

.roadmap-icon-name.tools {
    color: #AB9A01;
}

.roadmap-item-icon.tools {
    color: #AB9A01;
}

.roadmap-icon-name.actions {
    color: #b86060;
}

.roadmap-item-icon.actions {
    color: #b86060;
}

.roadmap-image {
    display: flex;
    height: 100%;
    width: auto;
}

.roadmap-image.tools {
    background-color: #0d66a0;
}

.roadmap-item-info-container {
    display: flex;
    flex-direction: column;
    width: 96%;
    height: 100%;
    justify-content: space-between;
}

.roadmap-item-info-container.actions {
    height: 3em;
}

.roadmap-item-info-container h4 {
    font-size: 1.4rem;
    font-weight: normal;
    margin-top: .6em;
    padding: 0;
}

.roadmap-item-icons-list-container {
    display: flex;
    flex-direction: row;
    color: var(--secondary-text-color);
    margin-bottom: 1em;
    gap: 1.5em;
    font-size: 1.2rem;
}


@media screen and (max-width: 1050px) {
    .learning-path-start-container {
        padding-top: 1em;
    }
    .roadmap-item-info-container h4 {
        font-size: 1.2rem;
    }
    .roadmap-element {
        height: 6em;
    }

    .learning-grid-item-header {
        font-size: .8em;
    }

    .courses .learning-grid-item-header {
        font-size: 1.2em;
        margin-top: .2em;
    }

    .learning-path-header {
        font-size: 1.4em;
    }

    .learning-grid-item-header {
        font-size: .9em;
    }

    .grid-item-container.articles {
        height: 6em;
    }
}

@media screen and (max-width: 750px) {
    .all-content-content-listed {
        padding-top: 3em;
    }

    .roadmap-item-icon-container {
        width: 11em;
    }

    .learning-path-start-container {
        justify-content: center;
        align-self: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        padding-top: 2em;
    }
    .roadmap-element {
        gap: .7em;
    }

    .roadmap-item-info-container h4 {
        font-size: 1.1rem;
    }
    .learning-grid-item-icon-container {
        font-size: .7rem;
    }

    .roadmap-item-checkbox {
        font-size: .7rem;
    }

    .roadmap-icon-name {
        font-size: .9rem;
    }

    .roadmap-item-icons-list-container {
        margin-bottom: .5em;
    }

    .learning-path-start-container {
        margin-top: 1em;
    }

    .content-container-switcher {
        align-self: center;
        right: inherit;
        left: inherit;
    }

    .content-container-switcher-button {
        width: 8em;
        padding: .4em;
        font-size: .8rem;
    }


    #action-items-disclosure {
        font-size: .6rem;
    }

    .roadmap-element {
        height: 5em;
    }

    .articles .learning-grid-item-description {
        display: none;
    }

    .roadmap-item-content-container {
        flex-direction: column;
    }

    .roadmap-item-content-container.actions .roadmap-item-image-container {
        left: 0;
        margin-top: .5em;
        width: 100%;
        background: none;
    }

    .roadmap-element {
        height: auto;
    }

    .roadmap-image {
        width: 100%;
        height: auto;
    }

    .learning-path-container {
        margin-left: 0;
    }

    .roadmap-item-content-container {
        gap: 0;
    }

    .roadmap-item-info-container {
        gap: .5em;
    }

    .roadmap-item-icons-list-container {
        gap: 1em;
    }

    .roadmap-item-icon {
        font-size: 1.5rem;
    }

    .roadmap-icon-name{
         font-size: 1.3rem;
    }

}