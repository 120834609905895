.premium-overlay-content {
    display: flex;
    align-items: center;
    padding: 2em 3em 4em 3em;
    flex-direction: column;
    position: relative;
    max-width: none;
    justify-content: center;
}

.premium-overlay-content.loading-content {
    visibility: hidden;
}

.premium-options-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.premium-options-container h4 {
    display: flex;
    color: var(--text-color);
    font-weight: bold;
    align-self: start;
    font-size: 2em;
}

.premium-options-container h5 {
    color: var(--text-color);
    display: flex;
    align-self: start;
    padding: 0;
    margin: 0;
    font-size: .8em;
    font-weight: lighter;
    margin-bottom: .2em;
}

.premium-options-container.right h5 {
    display: flex;
    align-self: start;
    color: var(--text-color);
    font-weight: bolder;
    font-size: 1.4em;
    margin-left: .5em;
    margin-bottom: 0;
}

.premium-option-choices-container {
    display: flex;
    flex-direction: column;
    gap: 1.4em;
    width: 100%;
    align-self: start;
    margin-top: 1em;
}

.risk-free-text {
    display: flex;
    font-size: .8em;
    margin-top: .5em;
    color: var(--secondary-text-color);
}

.coupon-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: .4em;
    color: var(--primary-blue);
    padding: 0;
    font-size: .8em;
}

.coupon-question-text {
    display: flex;
    font-size: 1rem;
}

.coupon-submit-right-arrow {
    display: flex;
    align-self: center;
    color: white;
    padding: 0;
    margin: 0;
}

.coupon-code-text {
    padding: 0;
    margin: 0;
    font-size: .9em;
    align-self: center;
}

.coupon-submit-right-arrow-container {
    display: flex;
    height: 2.3em;
    background: var(--primary-blue);
    width: 2em;
    border-radius: 0 .2em .2em 0;
    justify-content: center;
    cursor: pointer;
    margin-right: 1em;
}

.coupon-code-input {
    display: flex;
    padding: .4em;
    font-weight: bolder;
    color: var(--primary-blue);
    width: 10em;
    border-radius: .2em 0 0 .2em;
    border: 1px solid gray;
    text-transform: uppercase;
}

.coupon-code-input:focus {
    outline: none;
}

.premium-options-container.left {
    gap: 0;
    align-self: start;
}

#premium-overlay-close-button {
    cursor: pointer;
}

.premium-options-container.right {
    border-radius: 1em;
    padding: 1em;
    align-items: start;
    background: var(--secondary2);
}

.premium-option-container {
    display: flex;
    padding: 1.6em 1.4em 1.6em 1.4em;
    flex-direction: row;
    align-items: center;
    position: relative;
    border-radius: .6em;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.premium-option-recommended-container {
    position: absolute;
    right: 0;
    left: 0;
    top: -.5em;
    background: var(--primary1);
    border-radius: 1em 1em 0 0;
    justify-content: center;
    align-items: center;
    padding: .2em .4em;
}

.premium-option-recommended-container p {
    display: flex;
    color: white;
    line-height: .8em;
    font-size: .8em;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-self: center;
}

.premium-option-price {
    padding: 0 0 2em 0;
    margin: 1em;
}

.premium-option-container p {
    padding: 0;
    margin: 0;
    display: flex;
    align-self: center;
}

.premium-option-price-amount {
    font-size: 1.6em;
    line-height: 1em;
    padding-right: .3em;
    font-weight: bolder;
}

.premium-option-interval-slash {
    font-size: .6em;
}

.premium-includes-container {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: .4em;
    font-size: 1.4em;
    margin-left: .5em;
}

.premium-includes-container p {
    padding: 0;
    margin: 0;
    font-size: 1.2em;
}

.premium-includes-checkmark {
    color: white;
    border-radius: 1em;
    padding: .25em;
    font-size: .6em;
    background: var(--primary-blue);
}

.premium-options-close {
    position: absolute;
    right: .5em;
    top: .5em;
    font-size: 1.2em;
}

.loading-spinner-container {
    position: absolute;
    align-self: center;
}

.premium-option-right-arrow {
    color: var(--primary-blue);
    position: absolute;
    right: 1em;
}

.premium-includes-text-container {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.premium-includes-num {
    color: var(--primary-blue);
}

.premium-includes-text-container h6 {
    margin: 0;
    padding: 0;
    font-size: .8em;
    line-height: 1em;
}

.premium-includes-text-container p {
    margin: 0;
    padding: 0;
    line-height: 1em;
    font-size: .6em;
    max-width: 25em;
}

.premium-options-list-container {
    display: flex;
    flex-direction: column;
    margin-top: .8em;
    gap: 1em;
}

@media screen and (max-width: 1000px) {
    .premium-overlay-content {
        flex-direction: column;
        max-width: 96%;
        margin: 0 2%;
        padding: 1.2em;
    }

    .premium-options-container.left {
        width: 100%;
    }


    .coupon-question-text {
        display: flex;
        font-size: .8rem;
    }

}

@media screen and (max-width: 500px) {
    .premium-overlay-content {
        padding: 1.2em;
    }

    .premium-options-container h4 {
        font-size: 1.6em;
    }

    .premium-includes-container {
        font-size: 1.4em;
    }

    .premium-options-container.right h5 {
        font-size: 1.2em;
    }

    .premium-includes-text-container h6 {
        line-height: .8em;
    }

    .premium-option-container {
        padding: 1.4em 1.2em 1.4em 1.2em;
    }

    .premium-option-price-amount {
        font-size: 1.4em;
    }
}