.resume-uploaded-container {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    background: white;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
}

.resume-uploaded-container p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-lines: 1;
    white-space: nowrap;

}

.resume-uploaded-icon-container {
    background: var(--primary-blue);
    height: 100%;
    padding: 1em;
    margin-right: 1em;
}

#resume-uploaded-icon {
    margin: 0;
    font-size: 1.5em;
    color: white;
}

.resume-uploaded-text-container {
    display: flex;
    flex-direction: column;
    max-width: 80%;
    align-self: center;

}

.resume-uploaded-text-container h5 {
    display: flex;
    font-size: .8em;
    margin: 0;
    padding: 0;
    color: var(--primary-blue);
}