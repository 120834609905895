:root {
    --primary1: #eb5b04;
    --primary2: #feefeb;
    --secondary2: #e0f2fd;
    --white-text-color: #FFFFFF;
    --text-color: #464646;
    --text-color-alt: #454545;
    --secondary-text-color: #4C4C4C;
    --background-darken: rgba(0, 0, 0, 0.5);
    --cancel-color: #ff2a34;
    --blue-background: #e0eefe;
    --programming-resumes-primary: #C6500C;
    --tech-interview-prep-primary: #168362;
    --background-container-default: #fcfcfc;
    --border-default: #d9d9d9;
    --background-brand-default: #1d1d1d;
    --text-brand-default: #f5f5f5;
    --background-neutral-default: #d1d1d1;
    --primary-blue: #3F81BF;
    --primary-blue-hover: #395d87;

    --button-border-radius: .4em;
}