/* Base Styles */
html {
    font-size: 16px; /* Sets the base font size (1rem = 16px) */
    font-family: 'Arial', sans-serif; /* Set the base font family */
}

body {
    margin: 0;
    padding: 0;
    font-size: 1rem; /* This equals 16px by default */
    color: var(--text-color); /* Default text color */
    line-height: 1.5; /* Line height for readability */
    background-color: #f8f8f8; /* Default background */
}

/* Heading Styles */
h1, h2, h3, h4, h5, h6 {
    font-family: 'Arial', sans-serif;
    margin: 0;
}

/* Customize specific heading font sizes */
h1 {
    font-size: 2.5rem; /* 40px */
}

h2 {
    font-size: 2rem; /* 32px */
}

h3 {
    font-size: 1.75rem; /* 28px */
}

h4 {
    font-size: 1.5rem; /* 24px */
}

h5 {
    font-size: 1.25rem; /* 20px */
}

h6 {
    font-size: 1rem; /* 16px */
}

/* Paragraphs */
p {
    font-size: 1rem; /* 16px by default */
    margin-bottom: 1em;
}

.go-premium-button {
    display: flex;
    padding: .9em 1.5em;
    background: var(--primary-blue);
    color: white;
    border: 0;
    border-radius: 2em;
    font-size: 1em;
    font-weight: bolder;
    text-align: center;
    justify-content: center;
    cursor: pointer;
}

.hero-text-container .go-premium-button {
    padding: .9em 2em;
}

.premium-button-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 0;
}

.premium-button-container.testimonial {
    margin-top: 2em;
}


.premium-item-icon {
    position: absolute;
    background: var(--primary-blue);
    color: white;
    border-radius: 1em;
    font-size: 1.2em;
    padding: .25em;
    top: 1em;
    left: 1em;
}

.premium-item-icon-button {
    background: white;
    color: var(--primary-blue);
    border-radius: 1em;
    align-self: center;
    font-size: .7em;
    padding: .25em;
    margin-left: .5em;
}

.course-page-info .premium-item-icon {
    display: flex;
    position: initial;
    font-size: .8em;
}

/* Accessibility Helper Classes */
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}