.course-page-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 70em;
    margin-top: 2em;
    align-self: center;
}

.course-page-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-self: center;
    color: var(--primary1);
}

.course-page-thumbnail-container {
    display: flex;
    position: relative;
}

.courses-features-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: center;
    color: var(--text-color);
}

.courses-features-section ul {
    margin: 0;
    padding-left: 2em;
}

.courses-features-section li {
    line-height: 1.2em;
}

.course-lessons-list {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 1em;
}

.section-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: .5em;
}

.section-container h5 {
    color: var(--primary1);
}

.section-container h6 {
    color: var(--text-color);
}

.lesson-icon-not-started {
    color: white;
    border: 1px solid gray;
    border-radius: 100%;
    margin-left: 1em;
}

.lesson-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
    background: var(--secondary2);
    border: 1px solid gray;
    border-radius: .2em;
    padding: 1em 0;
    align-items: center;
    gap: .8em;
}

.course-page-header img {
    width: 30em;
    object-fit: contain;
    border-radius: 1em;
    align-self: start;
}

.begin-course-cta {
    background: var(--primary-blue);
    color: white;
    width: 10em;
    border: none;
    border-radius: 1em;
    padding: .5em 1em;
    font-size: 1em;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 1em;
}

.course-page-info {
    display: flex;
    flex-direction: column;
    padding: 0 1em;
}

.course-page-premium-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .4em;
    color: var(--primary-blue);
}

.course-page-info p {
    padding: 0;
    margin: 0;
    font-weight: bolder;
}

.course-page-info h3 {
    display: flex;
    color: var(--primary-blue);
    margin: 0;
    padding: 0;
    line-height: 1.2em;
}

.course-page-info h4 {
    display: flex;
    color: var(--text-color);
    font-size: 1em;
}

.course-page-icons-container {
    display: flex;
    flex-direction: row;
    gap: 1em;
    font-size: 1.2em;
    color: var(--primary1);
    margin-top: 1em;
    flex: 1;
}

.resource-pdf-section {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 2em 0;
}

.resource-pdf-content {
    display: flex;
    width: 100%;
    height: 50em;
}

.interview-video-container {
    display: flex;
    margin: 2em 0;
}

.interview-play-preview {
    display: flex;
    margin-top: 1em;
    width: 100%;
    position: relative;
    justify-content: center;
}

.interview-play-preview img {
    width: 100%;
    height: auto;
}

.image-preview-overlay {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    background: rgba(0, 0, 0, 0.30);
}

.interview-play-preview-icon-container {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.play-button-overlay {
    font-size: 6em;
    color: white;
}

@media screen and (max-width: 1000px) {
    .course-page-info {
        padding: 0;
        margin-top: .5em;
    }

    .course-page-header img {
        width: 100%;
    }

    .course-page-header {
        flex-direction: column;
    }

    .course-page-info h3 {
        font-size: 1.3em;
    }

    .course-lessons-list h3 {
        margin-top: 1em;
        font-size: 1.6em;
    }

    .begin-course-cta {
        width: 100%;
    }
}