.firebase-auth-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    overflow: hidden;
}

.firebase-auth-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.firebase-auth-modal .header-logo {
    margin-bottom: 0em;
    display: flex;
    align-self: center;
    position: relative;
    cursor: auto;
    left: 0;
    padding-top: .5em;
}

.firebase-auth-modal h4 {
    font-size: 1em;
}

body.modal-open {
    overflow: hidden;
}

.auth-container-close {
    font-size: 1.1em;
    color: black;
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;
}

.input-field {
    padding: 0.8em;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 0.3em;
}

.submit-button {
    background-color: var(--primary-blue);
    color: white;
    padding: 0.8em;
    border: none;
    border-radius: 0.3em;
    font-size: 1em;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #0259c1;
}

.form-container {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2em 2em 0em 2em;
    text-align: center;
    background-color: #ffffff;
    max-width: 300px;
}

.divider {
    margin: .6em 0;
}

.form-container .terms {
    margin-top: 1em;
    font-size: 0.8em;
    color: #666;
}

.form-container h1 {
    font-size: 2em;
}

.form-container p {
    margin: 0.5em 0;
    color: var(--text-color);
}
