.testimonials-module-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: center;
    align-items: center;
    margin-top: 0em;
    padding-top: 2em;
    margin-bottom: 0em;
    position: relative;
    gap: .5em;
}

.testimonials-items-container {
    display: flex;
    width: 100%;
    align-self: center;
    flex-direction: row;
    background: var(--background-container-default);
    gap: 1em;
}

.testimonial-top-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.testimonial-item-container {
    display: flex;
    flex-direction: column;
    padding: .8em;
    border: 1px solid silver;
    border-radius: 1em;
    flex: 1;
    gap: .3em;
    position: relative;
}

.testimonial-user-container {
    display: flex;
    flex-direction: row;
    gap: .4em;
}

.testimonial-user-container img {
    width: 1.8em;
    height: 1.8em;
    border-radius: 100%;
    border: 2px solid var(--secondary2);
}

.testimonial-user-name-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#testimonial-name {
    font-size: .8em;
}

#testimonial-specialty {
    font-size: .6em;
}

.testimonial-star-icon {
    font-size: .8em;
    color: #feb800;
}

.testimonial-item-container p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: .8em;
    line-height: 1.2em;
    padding: 0;
    margin: 0;
}

.testimonial-rating-container {
    display: flex;
    flex-direction: row;
    margin-right: .5em;
    gap: .5em;
}

.testimonial-circles-container {
    display: flex;
    justify-content: center;
    gap: 0.5em;
}

.testimonial-circle {
    width: 0.4em;
    height: 0.4em;
    border-radius: 50%;
    background-color: #ccc;
    transition: background-color 0.3s ease;
}

.testimonial-circles-container .active {
    background-color: #555;
}

@media screen and (max-width: 1000px) {
    .testimonials-items-container {
        flex-direction: column;
    }
}