.header-logo {
    width: 7em;
    height: auto;
    object-fit: contain;
    display: flex;
    cursor: pointer;
}

.log-in-button {
    display: flex;
    color: var(--primary-blue);
    padding: .4em 1em;
    border-radius: var(--button-border-radius);
    font-size: 1em;
    text-align: center;
    justify-content: center;
    background: none;
    cursor: pointer;
    border: none;
}

.register-button {
    display: flex;
    color: var(--white-text-color);
    background: var(--primary-blue);
    padding: .6em 1em;
    border-radius: var(--button-border-radius);
    font-size: 1em;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    border: none;
}

.header-module-content {
    display: flex;
    align-self: center;
    width: 96%;
    max-width: 100em;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
    margin-top: 1em;
    height: 100%;
}

.header-module-container {
    display: flex;
    width: 100%;
    height: 3em;
    justify-content: center;
    align-items: center;
    padding-top: .5em;
    padding-bottom: .5em;
    position: relative;
    box-shadow: 0px 4px 2px -2px rgba(193,193,193,0.5);
}

.header-account-icon {
    font-size: 2.6em;
    color: var(--primary-blue);
    cursor: pointer;
}

.header-logged-out-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.header-logged-out-web-container {
    display: flex;
    flex-direction: row;
    gap: .5em;
    justify-content: center;
    align-items: center;
    align-self: center;
}
