.auth-button.google {
    background-color: white;
    color: var(--text-color);
    border: 1px solid #c1c1c1;
    padding: 10px 0px;
    /*font-weight: 500;*/
    font-size: 16px;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: background-color 0.3s ease;
}

.auth-button.google:hover {
    border: 1px solid #5e5e5e;
}

.auth-button img {
    width: 1.4em;
}