.progress-bar-container {
    display: flex;
    width: 100%;
    height: 5px;
    background-color: #ddd;
}

.progress-bar {
    width: 0;
    height: 100%;
    background-color: var(--primary-blue);
    /*animation: progress-animation 15s linear forwards;*/
}

@keyframes progress-animation {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}