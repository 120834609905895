.tool-page-container {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    background: #f9f9f9;
    align-items: center;
    justify-content: center;
    margin-top: .5em;
    margin-bottom: .5em;
}

.tool-page-content {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 100em;
    padding-top: 2em;
    padding-bottom: 2em;
    justify-content: space-between;
    align-items: start;
    gap: 1em;
}

.tool-text-container {
    text-align: start;
    width: 100%;
}

.tool-text-container h1 {
    font-size: 2rem;
    font-weight: 700;
    color: var(--text-color);
    margin-bottom: 0rem;
}

.tool-text-container h2 {
    font-size: 1.25rem;
    color: var(--primary-blue);
    margin: 0;
}

.resume-reviewer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    width: 100%;
    background: var(--blue-background);
    border: 1px solid #ddd;
    border-radius: 6px;
}

.resume-reviewer-not-analyzed-container {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex-direction: column;
    flex: 1;
    align-items: center;
}

.resume-reviewer-uploaded-container {
    display: flex;
    width: 100%;
    flex: 1;
    height: 100%;
    flex-direction: column;
    gap: 1.5em;
    align-items: center;
    justify-content: center;
}

.tools-job-description-input {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2em;
}

.resume-reviewer-container h2 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
    color: #444;
    text-align: center;
}

.resume-reviewer-title-container h2{
    margin: 0;
}

.resume-reviewer-title-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    margin-top: 1em;
    margin-bottom: 1em;
    gap: .3em;
}

.tools-file-uploader-container {
    display: flex;
    width: 90%;
    flex-direction: column;
    margin-bottom: 2em;
}

.tools-needed-info-header {
    font-size: 1.2rem;
    padding: 0;
    margin: 0;
    width: 90%;
    text-align: start;
}

.analyze-resume-button {
    display: flex;
    padding: 1em 2em;
    background: var(--primary-blue);
    color: white;
    border-radius: 30px;
    border: none;
    font-size: 1.1rem;
    cursor: pointer;
    margin-bottom: 1.5em;
}

.analyze-resume-button:disabled {
    background: #7c7e8d;
}

.tool-results-container {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 2em;
    align-items: center;
}

.specific-tool-result {
    display: flex;
    position: relative;
    height: 100%;
    width: 94%;
    flex-direction: column;
}

.resume-analysis-result {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    flex-direction: column;
}

.tool-text-container h4 {
    color: var(--primary1);
    background: var(--primary2);
    width: 5em;
    padding: .2em;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 1em;
    text-align: center;
}

.analysis-section-header {
    padding: 0;
    color: var(--primary-blue);
}

.analysis-section-list {
    margin: 0;
    padding-bottom: 1em;
}

.resume-analysis-result h3 {
    color: #444;;
}

.resume-analyzing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 20em;
    width: 90%;
}

.resume-analyzing-container h2 {
    font-size: 1.75rem;
    color: var(--primary-blue);
    margin-bottom: 1rem;
}

.resume-analyzing-container p {
    font-size: 1.1rem;
    color: #666;
}

#resume-analysis-result-redo-icon {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.8rem;
    cursor: pointer;
}

#tools-page-job-description-input {
    line-height: 3;
    text-align: left;
    width: 90%;
}

@media screen and (max-width: 1200px) {
    .tool-text-container h1 {
        font-size: 1.7rem;
    }
}

@media screen and (max-width: 600px) {
    .tool-text-container h1 {
        font-size: 1.5rem;
    }

    .tool-text-container h2 {
        font-size: 1rem;
    }

    .resume-reviewer-title-container h2 {
        font-size: 1.2rem;
    }

    .resume-reviewer-title-container {
        font-size: 1.3em;
    }
}