.onboarding-flow-container {
    width: 100%;
    margin: 2em 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    position: relative;
}

.close-onboarding-button {
    display: flex;
    position: absolute;
    top: .5em;
    right: .5em;
    color: var(--border-default);
    font-size: 1.4rem;
}

.progress-bar-wrapper {
    width: 100%;
    height: 8px;
    background: #eee;
    border-radius: 4px;
    margin-bottom: 1em;
    overflow: hidden;
}

.progress-bar {
    height: 100%;
    background-color: var(--primary-blue);
    transition: width 0.4s ease;
}

.onboarding-image-subtext {
    display: flex;
    color: var(--secondary-text-color);
    font-size: 1.2rem;
    margin-bottom: .2em;
}

.flow-content {
    width: 100%;
    margin-top: 5em;
    display: flex;
    justify-content: center;
}

.flow-content.no-progress-bar {
    margin-top: 2em;
}

.onboarding-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    position: relative;
}

.onboarding-content-container.pricing-section {
    padding-top: 1em;
}

.onboarding-content-container.final-section {
    padding-bottom: 3em;
}

.onboarding-loader-text {
    font-size: 1.5em;
    font-weight: normal;
    color: var(--text-color);
    text-align: start;
    margin-top: 1em;
}

.onboarding-loader-subtext {
    display: flex;
    font-size: 1.2rem;
    color: var(--primary-blue);
    margin: 0;
    padding: 0;
}

.confirmation-message {
    font-size: 1.5em;
    font-weight: normal;
    color: var(--primary-blue);
    animation: fadeIn 1.5s ease-in-out;
    text-align: center;
    margin-top: 1em;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }
    50% {
        opacity: 1;
        transform: scale(1.05);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

#onboarding-next-button {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
}

.onboarding-main-text-preface {
    text-align: start;
    color: var(--secondary-text-color);
    font-size: 1.2rem;
}

.onboarding-content-container h2 {
    width: 100%;
    text-align: start;
}

.onboarding-content-container h3 {
    width: 100%;
    text-align: start;
}

.wizard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wizard-main-text {
    width: 100%;
    text-align: start;
    font-size: 1.8rem;
    color: var(--text-color);
}

.wizard-main-subtext {
    width: 100%;
    text-align: start;
    color: var(--secondary-text-color);
    font-size: 1rem;
}

.wizard-question {
    font-size: 1.4rem;
    margin-bottom: 2rem;
}

.option-buttons-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1rem;
    margin: 0;
    margin-top: 1em;
    align-items: stretch;
    justify-items: center;
}

.option-button {
    height: 8em;
    width: 15em;
    max-width: 45vw;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s, color 0.2s;
    text-align: center;
    font-size: 1rem;
    color: #333;
}

.option-button-icon {
    width: 2.5em;
    height: 2.5em;
    margin-bottom: 0.5rem;
    transition: filter 0.2s;
}

.option-button.active {
    background-color: var(--primary-blue);
    border-color: var(--primary-blue);
    color: #fff;
}

.option-button.active .option-button-icon {
    filter: brightness(0) invert(1);
}

.onboarding-image {
    width: 12em;
    margin-top: 1rem;
}

.map-progress-circle {
    width: 200px;
    height: 200px;
    position: relative;
    margin-bottom: .5rem;
    margin-top: 4rem;
}

.map-icon-overlay {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.trial-section {
    width: 100%;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.pricing-section p {
    font-size: 1.4rem;
}

.onboarding-pricing-cancel-text {
    font-size: .8rem;
    margin-bottom: .3rem;
    color: var(--secondary-text-color);
}

.trial-timeline {
    display: flex;
    flex-direction: column;
    align-items: start;
    position: relative;
    margin-top: 1.5rem;
    margin-bottom: .5rem;
}

.trial-item-section {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.trial-item-icon-section {
    display: flex;
    flex-direction: column;
}

.trial-item-icon-section.completed .trial-item-icon {
    background: var(--primary-blue);
}

.trial-item {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    margin-bottom: 1.5rem;
}

.trial-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: start;
}

.trial-text-header.completed {
    color: var(--primary-blue);
}

.trial-item-icon {
    display: flex;
    top: 0;
    background: #757575;
    color: white;
    border-radius: 999em;
    font-size: 1.3rem;
    overflow: visible;
    padding: .5em;
}

.trial-dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ddd;
    flex-shrink: 0;
    margin-top: 5px;
}

.checked-dot {
    background: var(--primary-blue);
}

.lock-dot {
    background: #aaa;
}

.bell-dot {
    background: #bbb;
}

.star-dot {
    background: #ccc;
}

.trial-text h3 {
    font-size: 1.4rem;
    margin: 0;
}

.trial-text p {
    margin: 0;
    font-size: 1.2rem;
    color: #555;
}

.trial-connector {
    width: 4px;
    background: #e0e0e0;
    height: 5rem;
    align-self: center;
}

.trial-connector.completed {
    background: var(--primary-blue);
}

.pricing-section p {
    padding-top: 0;
    margin-top: .5em;
}

.trial-image {
    width: 130px;
    margin: 2rem 0 0 0;
}

.pricing-subtext {
    font-size: 1rem;
    color: #555;
    margin-top: 0.5rem;
}

.final-section h2 {
    margin-bottom: 1rem;
    font-size: 1.3rem;
}

.final-image {
    width: 130px;
    margin-bottom: 1rem;
}

.overview-item-content-container {
    display: flex;
    flex-direction: row;
    background: var(--background-container-default);
    border: 1px solid var(--border-default);
    border-radius: 8px;
    width: 100%;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    overflow: clip;
    gap: .6em;
    height: 6em;
    position: relative;
}

.overview-item-image-container {
    display: flex;
    position: relative;
    height: 100%;
    width: auto;
}

.overview-item-info-container {
    display: flex;
    text-align: start;
    padding: 0;
    flex-direction: column;
    margin: 0 .8em 0 0;
    height: 86%;
    width: 96%;
    justify-content: space-between;
}

.onboarding-overview-section-items-list {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    gap: 1em;
    width: 100%;
    height: 34em;
    overflow-y: clip;
    padding: 0 1em;
    border-bottom: 3px solid var(--border-default);
    position: relative;
}

.onboarding-overview-section-items-list::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 3%;
    width: 94%;
    height: 4em;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 1) 100%);
    pointer-events: none;
}

@keyframes fadeInSlide {
    from {
        opacity: 0;
        transform: translateY(400px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.overview-item-content-container {
    animation: fadeInSlide 3s ease forwards;
}

.overview-item-info-container h4 {
    font-size: .9rem;
    margin: 0;
    font-weight: normal;
    color: var(--text-color);
}

.learning-grid-item-icon-container h5 {
    font-weight: normal;
}

.overview-item-icons-list-container {
    display: flex;
    flex-direction: row;
    color: var(--secondary-text-color);
    gap: 1.5em;
    font-size: 1rem;
}

.overview-image {
    display: flex;
    height: 100%;
    width: auto;
}

.roadmap-item-icons-list-container.overview-preview {
    margin: 0;
    margin-bottom: .5em;
    font-size: 1rem;
}

@media screen and (max-width: 800px) {
    .overview-item-content-container {
        height: 4em;
    }

    .onboarding-overview-section-items-list {
        height: 24.4em;
    }

    .roadmap-item-icon-container {
        font-size: .8rem;
    }

    .overview-item-info-container h4 {
        font-size: .8rem;
    }

    .overview-item-image-container .roadmap-item-icon-container {
        text-align: start;
        justify-content: start;
        padding-left: 1em;
        width: 6.5em;
    }

    .overview-item-image-container .roadmap-item-icon-container .roadmap-item-icon {
        font-size: .8rem;
    }

    .overview-item-image-container .roadmap-item-icon-container .roadmap-icon-name {
        font-size: .8rem;
    }

    .overview-item-icons-list-container .learning-grid-item-icon-container {
        font-size: .6rem;
    }

    .onboarding-content-container .welcome-image {
        width: 10em;
        margin-top: 2em;
    }

    .flow-next-button {
        padding: .8rem 2rem;
        font-size: 1rem;
    }

    .map-progress-circle {
        width: 100px;
        height: 100px;
    }

    .map-icon-overlay {
        height: 50px;
        width: 50px;
    }

    .onboarding-loader-text {
        font-size: 1.2rem;
        text-align: center;
    }

    .onboarding-loader-subtext {
        font-size: 1rem;
    }

    .trial-section h2 {
        font-size: 1.4rem;
    }

    .trial-text h3 {
        font-size: 1.2rem;
    }

    .trial-text p {
        font-size: .9rem;
    }

    .trial-item-icon {
        font-size: .9rem;
    }

    .trial-connector {
        height: 4em;
    }

}

@media screen and (max-width: 350px) {
    .overview-item-info-container h4 {
        font-size: .7rem;
    }

    .overview-item-icons-list-container {
        display: none;
    }
}







/* Page Transition - Smooth Fade In and Slide Up */
@keyframes fadeInSlideUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.flow-content {
    animation: fadeInSlideUp 0.6s ease-out;
}

@keyframes pop {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.option-button.active {
    animation: pop 0.2s ease-out;
}