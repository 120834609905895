.blog-page-container {
    width: 90%;
    max-width: 50em;
    justify-content: center;
    align-self: center;
    margin-top: 2em;
    margin-bottom: 2em;
}

.blog-page-container img {
    display: flex;
    width: 100%;
    height: 25em;
    border-radius: 1em;
    object-fit: cover;
    margin-bottom: 1em;
}

.all-blog-page-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 100em;
    justify-content: center;
    align-self: center;
    margin-top: 2em;
}

.all-blog-page-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
    gap: 1em;
    justify-items: center;
    align-self: center;
    margin-top: 2em;
    margin-bottom: 2em;
    width: 100%;
}

.blog-preview {
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    border-radius: 1em;
    gap: 0.5em;
    margin: 1em;
    background: var(--background-container-default);
    border: 1px solid var(--border-default);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
}

.blog-preview:hover {
    transform: scale(1.02);
}

.blog-preview img {
    width: 100%;
    height: 12em;
    object-fit: cover;
    border-radius: 0.5em;
}

.blog-preview h2 {
    font-size: 1.5em;
}

.blog-preview p {
    padding: 0;
    margin: 0;
}

.blog-snippet {
    font-size: 1em;
    color: #555;
}

.blog-preview-text-container {
    display: flex;
    flex-direction: column;
    padding: 1em;
}

.read-more {
    text-decoration: none;
    color: var(--primary-blue);
    font-weight: bold;
    margin-top: auto;
}
.read-more:hover {
    text-decoration: underline;
}

@media screen and (max-width: 800px) {
    .all-blog-page-content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}