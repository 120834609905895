.account-container {
    display: flex;
    width: 100%;
    align-self: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
    margin-bottom: 20em;
    gap: 1em;
}

.account-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .5em;
}

.account-content-container h4 {
    font-size: 1.4em;
}

.account-content-container a, .account-logout-button {
    text-decoration: none;
    color: white;
    width: 100%;
    background: var(--primary-blue);
    padding: 1em 0;
    border-radius: 1em;
    text-align: center;
    border: none;
    font-size: 1em;
}

.account-logout-button {
    background: none;
    border: 1px solid var(--cancel-color);
    color: var(--cancel-color);
}