
.bc-button {
    background-color: var(--primary-blue);
    color: var(--white-text-color);
    font-size: 1.2rem;
    padding: 1rem 3rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.bc-button:hover {
    transform: scale(1.05);
    background-color: var(--primary-blue-hover);
}

.bc-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.header-text-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.bc-header-text {
    text-align: start;
    color: var(--text-color);
    font-size: 1.8rem;
    font-weight: bold;
}

.bc-subheader-text {
    text-align: start;
    font-size: 1.4rem;
    color: var(--primary-blue);
}

.bc-subheader-text.secondary-color-subheader {
    color: var(--secondary-text-color);
}

@media screen and (max-width: 800px) {
    .bc-header-text {
        font-size: 1.4rem;
    }

    .bc-subheader-text {
        font-size: 1rem;
    }
}