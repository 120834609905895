.hero-bars-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    gap: .4em;
    width: 100%;
}

.bar {
    height: .5em;
    border-radius: 1em;
}

.hero-bars-container.bottom {
    bottom: 1em;
    left: 0;
}

.hero-bars-container.top {
    top: 1em;
    right: 0;
    align-items: end;
}

.bar.orange {
    background-color: #e66a2a;
    width: 25%;
}

.bar.green {
    background-color: #5aa57b;
    bottom: 1.5em;
    width: 30%;
}

.bar.blue {
    background-color: var(--primary-blue);
    bottom: 3em;
    width: 35%;
}
