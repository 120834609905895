@import './ColorConstants.css';

.hero-main-container {
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: row;
    gap: 2em;
    justify-content: center;
    position: relative;
    align-items: center;
    align-self: center;
    width: 100%;
    height: 92vh;
}

.hero-text-video-container {
    display: flex;
    justify-content: center;
    width: 92%;
    max-width: 100em;
    flex-direction: row-reverse;
    gap: 4em;
    flex-wrap: wrap;
}

.hero-video-container {
    flex: 1;
    display: flex;
    max-width: 50%;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.hero-text-container {
    text-align: start;
    max-width: 50em;
    color: var(--text-color);
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-direction: column;
    min-width: 25em;
    flex: 1;
}

.hero-text-container p {
    text-align: start;
    padding: 0;
}

.hero-text-container h1 {
    width: 100%;
    font-size: 3rem;
    line-height: 1.2em;
    font-weight: 600;
    color: var(--text-color);
    margin: 0 ;
}

.hero-images-container-blue-bar {
    background-color: #439cff;
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    right: 1em;
    width: 30em;
    height: 100%;
    z-index: -1;
    transform: skew(-27deg);
    transform-origin: top left;
}

.hero-highlighted-text {
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 3rem;
    font-weight: 700;
    margin-left: -.5em;
}

.email-subscribe-input {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.email-subscribe-input-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 1.5em;
}

.email-subscribe-input-text {
    display: flex;
    width: 100%;
    padding: .5rem 1.25rem;
    border-radius: .25em 0 0 .25em;
    border: 2px solid var(--primary-blue);
    font-size: 1rem;
    line-height: 1.5rem;
}

.email-subscribe-input-text:focus {
    outline: 0px solid #4B9CD3;
}

.email-subscribe-input-button {
    display: flex;
    width: 11em;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    background: var(--primary-blue);
    border: none;
    color: var(--white-text-color);
    border-radius: 0 .25em .25em 0;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
}

.overlay-content .email-subscribe-input {
    width: 100%;
}

.bee-hive-sign-up {
    margin: 0;
    border-radius: 0px;
    background-color: transparent;
    width: 100%;
    display: flex;
    margin-top: 1em;
}

.email-sign-up-under-form-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
}

.hero-review-container {
    display: flex;
    flex-direction: row;
    margin-top: 1em;
    align-items: center;
}

.hero-review-container img {
    width: 1.2em;
    height: 1.2em;
    border-radius: 100%;
    border: 2px solid var(--secondary2);
}

.hero-testimonial-star-icon {
    font-size: .8em;
    color: #feb800;
}

#hero-testimonial-1 {
    margin-left: .5em;
    z-index: 4;
}

#hero-testimonial-2 {
    margin-left: -.8em;
    z-index: 3;
}

#hero-testimonial-3 {
    margin-left: -.8em;
    z-index: 2;
}

#hero-testimonial-more {
    margin-left: -.4em;
    z-index: 1;
    font-size: 3em;
    color: silver;
}

.hero-review-container {
    margin-top: 0;
}

@media screen and (max-width: 1100px) {

}

@media screen and (max-width: 500px) {
    .email-sign-up-under-form-container {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
}

.email-subscribe-input-text.error {
    border: 1px solid red;
}

.email-error-message {
    color: red;
    font-size: 0.9em;
    margin-top: 5px;
}


@media screen and (max-width: 1200px) {
    .hero-image-container {
        flex-direction: column-reverse;
    }

    .hero-text-container h1 {
        font-size: 2rem;
    }

    .hero-images-container-blue-bar {
        height: 50%;
    }

    .hero-highlighted-text {
        justify-content: center;
    }

    .hero-text-container {
        text-align: start;
        justify-content: start;
        align-items: start;
    }

    .hero-text-container .go-premium-button {
        align-self: center;
        justify-content: center;
        margin-bottom: 1em;
    }

    .hero-text-container h1 {
        font-size: 1.6rem;
    }

    .email-subscribe-input-text {
        padding: .3rem 1.25rem;
    }
}


@media screen and (max-width: 900px) {

    .hero-text-video-container {
        flex-direction: column;
        gap: 2em;
    }

    .hero-video-container {
        width: 96%;
        max-width: 20em;
    }

    .hero-text-container {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 100%;
        min-width: 8em;
    }

    .hero-text-container h1 {
        text-align: center;
        display: flex;
        align-self: center;
        justify-self: center;
        justify-content: center;
        font-size: 1.8rem;
        width: 100%;
    }

    .email-subscribe-input {
        align-self: center;
        justify-content: center;
        align-items: center;
    }

    .hero-text-container {
        width: 96%;
        max-width: 26em;
        align-self: center;
    }
}
