.footers-module-container {
    display: flex;
    color: var(--secondary-text-color);
    width: 100%;
    align-self: center;
    justify-content: center;
}

.footers-module-content {
    display: flex;
    width: 96%;
    flex-direction: row;
    justify-content: space-between;
    margin: 2em 0;
}

.footer-logo {
    width: 7em;
    height: auto;
    object-fit: contain;
}

.footer-link {
    cursor: pointer;
}

.contact-us-container {
    display: flex;
    flex-direction: column;
}

.legal-text-container {
    display: flex;
    flex-direction: column;
}

.legal-text-container a {
    color: inherit;
    text-decoration: none;
}

.legal-text-container p {
    margin: 0;
}

@media screen and (max-width: 1100px) {
    .footers-module-content {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .footers-module-container {
        flex-direction: column;
        justify-content: center;
        align-self: center;
        text-align: center;
    }

    .footer-logo {
        align-self: center;
        width: 8em;
    }
}