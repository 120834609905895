.email-sign-up-page-container {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    background: #f9f9f9;
    align-items: center;
    justify-content: center;
    margin-top: .5em;
    margin-bottom: .5em;
}

.email-sign-up-page-content {
    display: flex;
    flex-direction: row;
    width: 96%;
    max-width: 100em;
    padding-top: 6em;
    padding-bottom: 6em;
    justify-content: center;
    align-items: center;
}

.email-sign-up-image-container {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    margin-top: -1em;
}

.email-sign-up-image-container img {
    width: 90%;
    border-radius: 1em;
    object-fit: contain;
}

.email-sign-up-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
}

.email-sign-up-text-container h1 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.2em;
    margin-bottom: .3em;
    color: var(--secondary-text-color);
}

.email-sign-up-text-container h2 {
    font-size: 1.2rem;
    font-weight: 400;
    margin-top: 0;
    color: var(--secondary-text-color);
}

.email-sign-up-text-container h3 {
    font-size: 1.4rem;
    font-weight: 600;
    margin-top: 0;
    color: var(--join-cta-secondary);
}

.email-sign-up-text-container h5 {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0;
    color: var(--secondary-text-color);
}

.email-guide-container {
    display: flex;
    /*width: 100%;*/
    flex-direction: column;
    /*border: 4px solid var(--join-cta-secondary);*/
    /*border-radius: 2em;*/
    /*padding: 1em;*/
    /*margin-bottom: 1em;*/
}

.email-sign-up-under-form-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
}

.email-sign-up-text-container ul {
    list-style-type: disc;
    padding-left: 1.5rem;
    margin-top: .5em;
}

.email-sign-up-text-container li {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: var(--secondary-text-color);
}

.name-email-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    margin: 0 0 1rem 0;
}

.name-email-container input {
    flex: 1;
    padding: 0.8rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.email-sign-up-text-container button {
    background-color: var(--primary-blue);
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    width: 100%;
    padding: 1em 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #0056b3;
}

.email-sign-up-page-privacy-text {
    font-size: 0.8rem;
    color: var(--secondary-text-color);
    margin-top: 0.3rem;
    margin-left: .3rem;
    padding-left: 1em;
    text-align: center;
    align-self: center;
}

.bee-hive-sign-up {
    margin: 0;
    border-radius: 0px;
    background-color: transparent;
    width: 100%;
    display: flex;
    margin-top: 1em;
}

@media screen and (max-width: 1200px) {
    .email-sign-up-text-container h1 {
        font-size: 1.8rem;
    }

    .email-sign-up-text-container h3 {
        font-size: 1.2rem;
    }

    .email-sign-up-text-container li {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 1000px) {
    .email-sign-up-text-container h1 {
        font-size: 1.4em;
    }

    .email-sign-up-text-container h3 {
        font-size: 1.2em;
    }

    .email-sign-up-text-container li {
        font-size: 1.2em;
    }

    .email-sign-up-page-content {
        flex-direction: column-reverse;
    }

    .email-sign-up-image-container {
        width: 100%;
        max-width: 30em;
        margin-bottom: .5em;
    }

    .email-sign-up-text-container {
        width: 100%;
    }

    .name-email-container {
        flex-direction: column;
    }

    .email-sign-up-page-content {
        margin-top: 2em;
        margin-bottom: 2em;
    }
}

@media screen and (max-width: 500px) {
    .email-sign-up-page-privacy-text {
        padding-left: 0;
    }

    .email-sign-up-text-container h1 {
        font-size: 1.4em;
    }

    .email-sign-up-text-container h3 {
        font-size: 1.1em;
    }

    .email-sign-up-text-container li {
        font-size: 1.1em;
    }

    .email-sign-up-under-form-container {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .hero-review-container {
        margin-top: 0;
    }
}