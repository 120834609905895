.file-uploader-container {
    display: flex;
    height: 100%;
    flex: 1;
    align-items: center;
    padding: 2rem;
    margin: 0;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    flex-direction: column;
    gap: .5em;
    border-radius: 8px;
}

.file-uploader-container p {
    padding: 0;
    margin: 0;
}

.supports-file-types {
    font-weight: bold;
}