.lesson-page-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 100em;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
}

.video-player-container {
    display: flex;
    width: 100%;
}

.lesson-footer-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    margin-top: 1em;
}

.lesson-footer-container a {
    text-decoration: none;
    color: var(--text-color);
    display: flex;
    max-width: 40%;
}

.lesson-header-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: .4em;
}

.lesson-header-container a {
    text-decoration: none;
    color: var(--text-color);
}

.lesson-footer-button {
    display: flex;
    flex-direction: row;
    padding: .6em .8em;
    font-weight: bolder;
    border: 1px solid var(--primary-blue);
    border-radius: 2em;
    background: var(--primary-blue);
    color: white;
    text-decoration: none;
    align-items: center;

    gap: .2em;
}

@media screen and (max-width: 800px) {
    .lesson-footer-button {
        font-size: .6em;
    }
}