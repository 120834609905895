.video-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.video-player {
    display: flex;
    width: 100%;
    align-self: center;
    height: auto;
    aspect-ratio: 16/9;
    object-fit: contain;
}