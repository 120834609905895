.register-page-container {
    display: flex;
    height: 100vh;
    flex-direction: row;
    background-color: #ffffff;
}

.login-header-logo {
    position: absolute;
    left: 1em;
    top: 1em;
    width: 7em;
    height: auto;
    object-fit: contain;
    display: flex;
    cursor: pointer;
}

.form-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2em;
    text-align: center;
    background-color: #ffffff;
}

.register-page-bottom-info {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
}

.form-container h1 {
    font-size: 2em;
}

.form-container p {
    margin: 0.5em 0;
    color: var(--text-color);
}

.auth-buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    gap: 1em;
    margin: 1em 0 0 0;
}

.auth-button {
    padding: 0.8em 1.5em;
    font-size: 1em;
    border: none;
    border-radius: 0.3em;
    cursor: pointer;
}

.auth-button.google {
    background-color: white;

}

.divider {
    margin: 1em 0;
    font-size: 0.9em;
    color: #888;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;
    max-width: 300px;
}

.form-group {
    display: flex;
    width: 100%;
 }

.input-field {
    display: flex;
    width: 100%;
    padding: 0.8em;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 0.3em;
}

.submit-button {
    background-color: var(--primary-blue);
    color: white;
    padding: 0.8em;
    border: none;
    border-radius: 0.3em;
    font-size: 1em;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #0259c1;
}

.login-text,
.terms {
    margin-top: 1em;
    font-size: 0.9em;
    color: #666;
}

.login-text a,
.terms a {
    color: var(--primary-blue);
    text-decoration: none;
}

.login-text a:hover,
.terms a:hover {
    text-decoration: underline;
}

.image-container {
    flex: 1;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
}

@media screen and (max-width: 800px) {
    .register-page-container {
        flex-direction: column;
    }

    .form-container {
        margin-top: 4em;
        width: 80%;
        align-self: center;
    }
}