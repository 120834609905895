.page-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
}

.overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.30);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.overlay-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    display: flex;
    max-height: 96%;
    position: relative;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    overflow-y: auto;
}

.overlay-container-close {
    font-size: 1.1em;
    color: black;
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
}

.overlay-content-header {
    margin: .5em;
}

.overlay-content-text {
    margin: 0 .5em .5em .5em;
    font-weight: normal;
    color: var(--secondary-text-color);
}

.confirmation-modal-button-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: right;
    margin-top: 1em;
    gap: 1em;
}

#confirmation-modal-button-confirm {
    color: var(--text-brand-default);
    background: var(--background-brand-default);
}

.confirmation-modal-button-container button {
    background-color: transparent;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    font-weight: normal;
    outline: none;
    font-size: .9rem;
    color: var(--text-color);
    background: var(--background-neutral-default);
    border: 1px solid var(--border-default);
    padding: 1em;
    border-radius: 8px;
}

#confirmation-overlay-close-button {
    position: absolute;
    right: 1em;
    font-size: 1.2rem;
}