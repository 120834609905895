.discord-join-cta {
    display: flex;
    border-radius: .5em;
    background-color: #737df7;
    color: white;
    font-size: 1em;
    font-weight: bold;
    padding: .8em;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    gap: .5em;
}

.discord-join-cta-text {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.discord-icon {
    font-size: 1.5em;
}

.discord-cta-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    margin-top: 1em;
    margin-bottom: 1em;
    align-items: center;
    justify-content: center;
    padding-top: 4em;
    padding-bottom: 4em;
    gap: 1em;
}

.discord-cta-container h4 {
    margin-bottom: 0em;
    text-align: center;
    justify-content: center;
}